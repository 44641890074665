import React, { useEffect, useState, useRef, useContext } from "react";
import { BrowserView, MobileView, isBrowser } from "react-device-detect";
import Container from "react-bootstrap/Container";
import LoginModal from "../Modals/login_modal";
import CartModal from "../Modals/cart_modal";
import Modal from "react-bootstrap/Modal";
import MenuModal from "../Modals/menu_modal";
import { ApiService } from "../../Components/Services/apiservices";
import HeaderMenu from "../Elements/header_menu";
import sessionCartData from "../../Components/Elements/cart_session_data";
import SpinnerLoader from "../Elements/spinner_loader";
import DataContext from "../Elements/eventContext";
import QuickViewModal from "../Modals/quick_view_modal";
import CheckoutModal from "../Modals/checkout_modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import constant from "../Services/constant";
function Header({ state = "", innerHeader =""}) {
  const navigate = useNavigate();
  const didMountRef = useRef(true);
  const contextValues = useContext(DataContext)
  const dataArray = sessionCartData();
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [headerdata, setHeaderData] = useState({});

  useEffect(() => {
    if (didMountRef.current) {
      contextValues.setAddressSession(dataArray[0])
      contextValues.setCouponSession(dataArray[2])
      contextValues.setCartSummary(dataArray[3])
      if (localStorage.getItem("USER_TOKEN")) {
        cartSessionData();
      } else {
        contextValues.setCartCount(dataArray[1].length)
        contextValues.setCartSessionData(dataArray[1])
      }
      handleChangeCurrency();
      getheaderdata();
      getSettingsData();
      contextValues.setUserToken(localStorage.getItem("USER_TOKEN"));
    }
    didMountRef.current = false;
    if (isBrowser) {
      const handleScroll = () => {
        if (window.scrollY > 200) {
          document.getElementById("header_top").classList.add("fixed-head");
          const navbarHeight =
            document.querySelector(".fixed-header").offsetHeight;
          document.body.style.paddingTop = navbarHeight + "px";
        } else {
          document.getElementById("header_top").classList.remove("fixed-head");
          document.body.style.paddingTop = "0";
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const loginModal = () => {
    contextValues.setToggleLoginModal(!contextValues.toggleLoginModal)
  }

  const cartSessionData = () => {
    const dataString = {
      product_id: '',
    };
    ApiService.postData("cartSessionData", dataString).then((res) => {
      if (res.data.status === "success") {
        contextValues.setCartSessionData(res.data.resCartData)
        contextValues.setCartCount(res.data.resCartData.length)
        contextValues.setCartSummary(res.data.cartSummary)
      }
    });
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm.length >= 2) {
        fetchSearchResults();
      }
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const getheaderdata = () => {
    ApiService.fetchData("header-data").then((res) => {
      if (res.status === "success") {
        if (res.headerdata) {
          setHeaderData(res.headerdata);
        }
      }
    });
  };

  const getSettingsData = () => {
    ApiService.fetchData("settings").then((res) => {
      if (res.status === "success") {
        contextValues.setSettingData(res.sitesettings);
        contextValues.setSettingImageBaseUrl(res.setting_image_path)
      }
    });
  };


  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const fetchSearchResults = () => {
    const dataString = {
      query: searchTerm,
    };
    ApiService.postData("getsearchdata", dataString).then((res) => {
      if (res.status === "success") {
        setSearchResults(res.data);

        // setCatid(res.resCategory.cat_id);
      } else {
      }
    });
  };
  const wrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [selectedCurrency, setSelectedCurrency] = useState('INR');

  const handleChangeCurrency = () => {
    const MultiCurrencySession = localStorage.getItem("MULTI_CURRENCY");
    const parsedMultiCurrencySession = MultiCurrencySession ? JSON.parse(MultiCurrencySession) : {};
    let selectedValue = '';

    if (parsedMultiCurrencySession) {
      selectedValue = parsedMultiCurrencySession.cr_currency_select;
    } else {
      selectedValue = selectedCurrency;
    }

    const dataString = {
      selectedValue: selectedValue,
    };
    setSpinnerLoading(true);
    ApiService.postData("currency-rates", dataString).then((res) => {
      if (res.status === "success") {
        setSelectedCurrency(selectedValue);
        localStorage.setItem('MULTI_CURRENCY', JSON.stringify(res.rowCurrencyRates));
        setSpinnerLoading(false);
      } else {
        setSpinnerLoading(false);
      }
    });
  };

  const handleChange = (event) => {
    const MultiCurrencySession = localStorage.getItem("MULTI_CURRENCY");
    const parsedMultiCurrencySession = MultiCurrencySession ? JSON.parse(MultiCurrencySession) : {};
    let selectedValue = '';
    if (event) {
      selectedValue = event.target.value;
    } else {
      if (parsedMultiCurrencySession) {
        selectedValue = parsedMultiCurrencySession.cr_currency_select;
      } else {
        selectedValue = selectedCurrency;
      }
    }
    const dataString = {
      selectedValue: selectedValue,
    };
    setSpinnerLoading(true);
    ApiService.postData("currency-rates", dataString).then((res) => {
      if (res.status === "success") {
        setSelectedCurrency(selectedValue);
        localStorage.setItem('MULTI_CURRENCY', JSON.stringify(res.rowCurrencyRates));
        setSpinnerLoading(false);
        window.location.reload();
      } else {
        setSpinnerLoading(false);
      }
    });
  };
  const quickviewmodal = () => {
    contextValues.setToggleQuickViewModal(!contextValues.toggleQuickViewModal)
  }
  const cartModal = () => {
    contextValues.setToggleCartModal(!contextValues.toggleCartModal)
  }
  const menuModal = () => {
    contextValues.setToggleMenuModal(!contextValues.toggleMenuModal)
  }

  const searchResult = () => {
    if (searchTerm) {
      window.location.href = window.location.origin.toString() + '/search-result/' + searchTerm;
    } else {
      toast.error("Type something to search");
    }
  }

  const searchResultClick = (search) => {
    window.location.href = window.location.origin.toString() + '/search-result/' + search;
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchResult();
    }
  };
  return (
    <>
      {/* {spinnerLoading && <SpinnerLoader />} */}
      <BrowserView>
      <header className={innerHeader == "" ? "header" : "header " + innerHeader}>
      {headerdata.header_top ? (
            <div className="headerTop">
              <marquee>{headerdata.header_top}</marquee>
            </div>
          ) : null}
        <div className={state == "" ? "headerMiddle fixed-header " : "headerMiddle fixed-header " + state} id="header_top">
          <div className="container">
          <div className="header-left">
          <a href="/" className="logo">
                <img
                  src={contextValues.settingData.logo != null ? contextValues.settingImageBaseUrl + contextValues.settingData.logo : "/img/logo.png"} alt={contextValues.settingData.logo}
                  width="75"
                  height="74"
                />
              </a>
          </div>
          <div className="header-center">
          <HeaderMenu />
          </div>
          <div className="header-right justify-content-end">
                {contextValues.settingData.admin_multi_currency_status === 1 ? <div className="curreny-header">
                  <div className="curreny-header-select">
                    <select value={selectedCurrency} onChange={handleChange}>
                      <option value="INR">INR</option>
                      <option value="USD">USD</option>
                    </select>
                  </div>
                </div> : null}
                <div className="dheader-search">
                  <a className="search-link" href="javascript:void(0)" title="search">
                    <i className="d-icon-search"></i>
                  </a>
                  <div className="dheader-search-form">
                    <div className="dsf-inner">
                      <input type="text" placeholder="Search for products, categories & more..." onKeyPress={handleKeyPress} onChange={handleInputChange} autoComplete="off" value={searchTerm}></input>
                      <i className="d-icon-search" onClick={(e) => searchResult()}></i>
                    </div>
                    {searchTerm.trim() !== '' && searchTerm.trim().length >= 2 ? searchResults && searchResults.length > 0 ?
                      <ul>{
                        searchResults.map((value, index) => (
                          <a href="javascript:void(0)" onClick={(e) => searchResultClick(value.name)}>
                            <li key={index}>{value.name}</li>
                          </a>
                        ))
                      }
                      </ul>
                      :
                      <ul><li>No Records Found</li></ul>
                      :
                      null
                    }
                  </div>
                </div>
                {contextValues.userToken ? (
                  <>
                    <a
                      className="login-link"
                      href="/account/account-overview"
                      title="login"
                    >
                      <i className="d-icon-user"></i>
                    </a>
                    <a
                      className="wishlist"
                      href="/account/wishlist"
                      title="wishlist"
                    >
                      <i className="d-icon-heart"></i>
                    </a>
                  </>
                ) : (
                  <>
                    <a
                      className="login-link"
                      href="javascript:void(0)"
                      title="login"
                      onClick={loginModal}
                    >
                      <i className="d-icon-user"></i>
                    </a>
                    <a
                      className="wishlist"
                      href="javascript:void(0)"
                      title="wishlist"
                      onClick={loginModal}
                    >
                      <i className="d-icon-heart"></i>
                    </a>
                  </>
                )}
                <a
                  className="cart-toggle"
                  href="javascript:void(0)"
                  title="cart"
                  onClick={(e) => cartModal()}
                >
                  <i className="d-icon-bag"></i>
                  <span className="cart-count">{contextValues.cartCount}</span>
                </a>
              </div>
          </div>
        </div>
      </header>
      </BrowserView>
      <MobileView>
      <header className="header">
        {headerdata.header_top ? (
            <div className="headerTop">
              <marquee>{headerdata.header_top}</marquee>
            </div>
          ) : null}
   <div className={state == "" ? "headerMiddle fixed-header " : "headerMiddle fixed-header " + state} id="header_top">
   <div className="container">
    <div className="header-left">
    <a
              href="javascript:void(0)"
              className="sidenav-trigger"
              onClick={(e) => menuModal()}
            >
              <i className="d-icon-bars2"></i>
            </a>
            <a className="search-link" href="/search" title="search">
              <i className="d-icon-search"></i>
            </a>
    </div>
    <div className="header-center">
    <a href="/" className="mlogo">
              <img
                src={contextValues.settingData.logo != null ? contextValues.settingImageBaseUrl + contextValues.settingData.logo : "/img/logo.png"} alt={contextValues.settingData.logo}
                width="60"
                height="59"
              />
            </a>
    </div>
    <div className="header-right justify-content-end">
    {contextValues.userToken ? (
              <>
                <a
                  className="login-link"
                  href="/account/account-overview"
                  title="login"
                >
                  <i className="d-icon-user"></i>
                </a>
              </>
            ) : (
              <>
                <a
                  className="login-link"
                  href="javascript:void(0)"
                  title="login"
                  onClick={loginModal}
                >
                  <i className="d-icon-user"></i>
                </a>
              </>
            )}
            <a
              className="cart-toggle"
              href="javascript:void(0)"
              title="cart"
              onClick={(e) => cartModal()}
            >
              <i className="d-icon-bag"></i>
              <span className="cart-count">{contextValues.cartCount}</span> </a>
    </div>
    </div>
    </div>




          
        </header>
      </MobileView>
      <LoginModal />
      <Modal show={contextValues.toggleQuickViewModal} onHide={(e) => quickviewmodal()} className="quickViewModal">
        {contextValues.toggleQuickViewModal && (<QuickViewModal />)}
      </Modal>
      <Modal show={contextValues.toggleCartModal} onHide={(e) => cartModal()} className="right cart-modal">
        {contextValues.toggleCartModal && (<CartModal />)}
      </Modal>
      <Modal show={contextValues.toggleMenuModal} onHide={(e) => menuModal()} className="left menu-modal">
        {contextValues.toggleMenuModal && (<MenuModal />)}
      </Modal>
      {contextValues.toggleCheckoutModal && (<CheckoutModal />)}
    </>
  );
}
export default Header;
