import React, { useEffect, useState, useRef } from "react";
import { ApiService } from "../../Components/Services/apiservices";
import { useNavigate } from "react-router-dom";
import constant from "../../Components/Services/constant";
import Skeleton from "react-loading-skeleton";
function HeaderMenu() {
  const didMountRef = useRef(true);
  const [menuData, setMenuData] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (didMountRef.current) {
      menuelist();
    }
    didMountRef.current = false;
  }, []);
  const menuelist = () => {
    ApiService.fetchData("menue-list").then((res) => {
      if (res.status === "success") {
        setMenuData(res.data);
        setImageUrl(res.imageUrl);
      }
      setLoading(false);
    });
  };
  const gotToPage = (route) => {
    navigate(route);
  };

  const clearSession = () => {
    localStorage.removeItem('collection')
    localStorage.removeItem('category')
    localStorage.removeItem('FILTER_SESSION')
    localStorage.removeItem('SORT_SESSION')
    localStorage.removeItem('RANGE_SESSION')

  }

  const resetFilter = (url) => {
    localStorage.removeItem('collection')
    localStorage.removeItem('category')
    localStorage.removeItem('tag')
    localStorage.removeItem('FILTER_SESSION')
    localStorage.removeItem('SORT_SESSION')
    localStorage.removeItem('RANGE_SESSION')
    navigate(url)
  }
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light">
        <ul className="navbar-nav">
          {menuData.length > 0
            ? menuData.map((parent, indexParent) => {
              if (parent.menu_mega === 0) {
                if (parent.children.length > 0) {
                  if (parent.menu_categoryid > 0) {
                    return (
                      <li className="nav-item dropdown dropdown-hover" key={indexParent}  >
                        <a
                          className="nav-link "
                          href={"javascript:void(0)"}
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={(e) => resetFilter("/collection/category/" + parent.categories.cat_slug)}
                        >
                          {parent.menu_name}
                        </a>
                        <ul className="dropdown-menu submenu" aria-labelledby="navbarDropdown" >
                          {parent.children.map(
                            (firstChild, indexFirstChild) => {
                              if (firstChild.menu_categoryid > 0) {
                                return (
                                  <li key={indexFirstChild}>
                                    <a className="dropdown-item" href={"javascript:void(0)"} onClick={(e) => resetFilter("/collection/category/" + firstChild.categories.cat_slug)}>
                                      {firstChild.menu_name}
                                    </a>
                                  </li>
                                );
                              } else if (firstChild.menu_collectionid > 0) {
                                return (
                                  <li key={indexFirstChild}>
                                    <a className="dropdown-item" href={"javascript:void(0)"} onClick={(e) => resetFilter("/collection/" + firstChild.collection.cat_slug)}>
                                      {firstChild.menu_name}
                                    </a>
                                  </li>
                                );
                              } else if (firstChild.menu_pageid > 0) {
                                return (
                                  <li key={indexFirstChild}>
                                    <a className="dropdown-item" href={"javascript:void(0)"} onClick={(e) => resetFilter("/" + firstChild.pages.page_url)}>
                                      {firstChild.menu_name}
                                    </a>
                                  </li>
                                );
                              } else {
                                return (
                                  <li key={indexFirstChild}>
                                    <a className="dropdown-item" href={"javascript:void(0)"} onClick={(e) => resetFilter(firstChild.menu_customlink)}>
                                      {firstChild.menu_name}
                                    </a>
                                  </li>
                                );
                              }
                            }
                          )}
                        </ul>
                      </li>
                    );
                  } else if (parent.menu_collectionid > 0) {
                    return (
                      <li className="nav-item dropdown dropdown-hover" key={indexParent}>
                        <a
                          className="nav-link "
                          href={"javascript:void(0)"} onClick={(e) => resetFilter("/collection/" + parent.collection.cat_slug)}
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {parent.menu_name}
                        </a>
                        <ul
                          className="dropdown-menu submenu"
                          aria-labelledby="navbarDropdown"
                        >
                          {parent.children.map(
                            (firstChild, indexFirstChild) => {
                              if (firstChild.menu_categoryid > 0) {
                                return (
                                  <li key={indexFirstChild} onClick={clearSession} >
                                    <a
                                      className="dropdown-item"
                                      href={"javascript:void(0)"} onClick={(e) => resetFilter("/collection/category/" + firstChild.categories.cat_slug)}
                                    >
                                      {firstChild.menu_name}
                                    </a>
                                  </li>
                                );
                              } else if (firstChild.menu_collectionid > 0) {
                                return (
                                  <li key={indexFirstChild}
                                    onClick={clearSession} >
                                    <a
                                      className="dropdown-item"
                                      href={
                                        "/collection/" +
                                        firstChild.collection.cat_slug
                                      }
                                    >
                                      {firstChild.menu_name}
                                    </a>
                                  </li>
                                );
                              } else if (firstChild.menu_pageid > 0) {
                                return (
                                  <li key={indexFirstChild} onClick={clearSession} >
                                    <a
                                      className="dropdown-item"
                                      href={"/" + firstChild.pages.page_url}
                                    >
                                      {firstChild.menu_name}
                                    </a>
                                  </li>
                                );
                              } else {
                                return (
                                  <li key={indexFirstChild} onClick={clearSession} >
                                    <a
                                      className="dropdown-item"
                                      href={firstChild.menu_customlink}
                                    >
                                      {firstChild.menu_name}
                                    </a>
                                  </li>
                                );
                              }
                            }
                          )}
                        </ul>
                      </li>
                    );
                  } else if (parent.menu_pageid > 0) {
                    return (
                      <li
                        className="nav-item dropdown dropdown-hover"
                        key={indexParent} onClick={clearSession}
                      >
                        <a
                          className="nav-link "
                          href={"/" + parent.pages.page_url}
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {parent.menu_name}
                        </a>
                        <ul
                          className="dropdown-menu submenu"
                          aria-labelledby="navbarDropdown"
                        >
                          {parent.children.map(
                            (firstChild, indexFirstChild) => {
                              if (firstChild.menu_categoryid > 0) {
                                return (
                                  <li key={indexFirstChild} onClick={clearSession} >
                                    <a
                                      className="dropdown-item"
                                      href={
                                        "/collection/category/" +
                                        firstChild.categories.cat_slug
                                      }
                                    >
                                      {firstChild.menu_name}
                                    </a>
                                  </li>
                                );
                              } else if (firstChild.menu_collectionid > 0) {
                                return (
                                  <li key={indexFirstChild} onClick={clearSession} >
                                    <a
                                      className="dropdown-item"
                                      href={
                                        "/collection/" +
                                        firstChild.collection.cat_slug
                                      }
                                    >
                                      {firstChild.menu_name}
                                    </a>
                                  </li>
                                );
                              } else if (firstChild.menu_pageid > 0) {
                                return (
                                  <li key={indexFirstChild} onClick={clearSession} >
                                    <a
                                      className="dropdown-item"
                                      href={"/" + firstChild.pages.page_url}
                                    >
                                      {firstChild.menu_name}
                                    </a>
                                  </li>
                                );
                              } else {
                                return (
                                  <li key={indexFirstChild} onClick={clearSession} >
                                    <a
                                      className="dropdown-item"
                                      href={firstChild.menu_customlink}
                                    >
                                      {firstChild.menu_name}
                                    </a>
                                  </li>
                                );
                              }
                            }
                          )}
                        </ul>
                      </li>
                    );
                  } else {
                    return (
                      <li
                        className="nav-item dropdown dropdown-hover"
                        key={indexParent} onClick={clearSession}
                      >
                        <a
                          className="nav-link "
                          href={parent.menu_customlink}
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {parent.menu_name}
                        </a>
                        <ul
                          className="dropdown-menu submenu"
                          aria-labelledby="navbarDropdown"
                        >
                          {parent.children.map(
                            (firstChild, indexFirstChild) => {
                              if (firstChild.menu_collectionid > 0) {
                                return (
                                  <li key={indexFirstChild} onClick={clearSession} >
                                    <a
                                      className="dropdown-item"
                                      href={
                                        "/collection/" +
                                        firstChild.collection.cat_slug
                                      }
                                    >
                                      {firstChild.menu_name}
                                    </a>
                                  </li>

                                );
                              } else if (firstChild.menu_categoryid > 0) {
                                return (
                                  <li key={indexFirstChild} onClick={clearSession} >
                                    <a
                                      className="dropdown-item"
                                      href={
                                        "/collection/category/" +
                                        firstChild.categories.cat_slug
                                      }
                                    >
                                      {firstChild.menu_name}
                                    </a>
                                  </li>

                                );
                              } else if (firstChild.menu_pageid > 0) {
                                return (
                                  <li key={indexFirstChild} onClick={clearSession} >
                                    <a
                                      className="dropdown-item"
                                      href={"/" + firstChild.pages.page_url}
                                    >
                                      {firstChild.menu_name}
                                    </a>
                                  </li>
                                );
                              } else {
                                return (
                                  <li key={indexFirstChild} onClick={clearSession} >
                                    <a
                                      className="dropdown-item"
                                      href={firstChild.menu_customlink}
                                    >
                                      {firstChild.menu_name}
                                    </a>
                                  </li>
                                );
                              }
                            }
                          )}
                        </ul>
                      </li>
                    );
                  }
                } else {
                  if (parent.menu_categoryid > 0) {
                    return (
                      <li className="nav-item" key={indexParent} onClick={clearSession} >
                        <a
                          className="nav-link"
                          aria-current="page"
                          href={"/collection/category/" + parent.categories.cat_slug}
                        >
                          {parent.menu_name}
                        </a>
                      </li>
                    );
                  } else if (parent.menu_collectionid > 0) {
                    return (
                      <li className="nav-item" key={indexParent} onClick={clearSession} >
                        <a
                          className="nav-link"
                          aria-current="page"
                          href={"/collection/" + parent.collection.cat_slug}
                        >
                          {parent.menu_name}
                        </a>
                      </li>
                    );
                  } else if (parent.menu_pageid > 0) {
                    const currentUrl = window.location.pathname;
                    const menuUrl = "/" + parent.pages.page_url;
                    const isActive = currentUrl === menuUrl;
                    return (
                      <li className={`nav-item ${isActive ? 'active' : ''}`} key={indexParent} onClick={clearSession} >
                        <a
                          className="nav-link"
                          aria-current="page"
                          href={menuUrl}
                        >
                          {parent.menu_name}
                        </a>
                      </li>
                    );
                  } else {
                    return (
                      <li className="nav-item" key={indexParent} onClick={clearSession} >
                        <a
                          className="nav-link"
                          aria-current="page"
                          href={parent.menu_customlink}
                        >
                          {parent.menu_name}
                        </a>
                      </li>
                    );
                  }
                }
              } else {
                if (parent.children.length > 0) {
                  if (parent.menu_categoryid > 0) {
                    return (
                      <li
                        className="nav-item dropdown mega-droupdown dropdown-hover"
                        key={indexParent} onClick={clearSession}
                      >
                        <a
                          className="nav-link "
                          href={"/collection/category/" + parent.categories.cat_slug}
                          id="navbarDropdown"

                        >
                          {parent.menu_name}
                        </a>
                        <div
                          className="mega-menu dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <div className="container">
                            <div className="row g-3">
                              {parent.children.map(
                                (firstChild, indexFirstChild) => {
                                  if (firstChild.menu_categoryid > 0) {
                                    if (firstChild.menu_show_image === 1) {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild} onClick={clearSession}
                                        >
                                          <div className="mega-menu-imgbox">
                                            <a
                                              href={
                                                "/collection/category/" +
                                                firstChild.categories
                                                  .cat_slug
                                              }
                                            >
                                              <img
                                                src={
                                                  firstChild.menu_image !=
                                                    null
                                                    ? imageUrl +
                                                    firstChild.menu_image
                                                    : constant.DEFAULT_IMAGE
                                                }
                                                width="100%"
                                                height="100%"
                                              />
                                              {firstChild.menu_desc ?
                                                <div
                                                  className="mega-menu-imgbox-content"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      firstChild.menu_desc,
                                                  }}
                                                ></div> : null}

                                            </a>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mmlist">
                                            <div className="mmlist-title" onClick={clearSession} >
                                              <a
                                                href={
                                                  "/collection/category/" +
                                                  firstChild.categories
                                                    .cat_slug
                                                }
                                              >
                                                {firstChild.menu_name}
                                              </a>
                                            </div>
                                            <ul>
                                              {firstChild.children.map(
                                                (
                                                  thirdChild,
                                                  indexThirdChild
                                                ) => {
                                                  if (
                                                    thirdChild.menu_categoryid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        } onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/category/" +
                                                            thirdChild
                                                              .categories
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                  if (
                                                    thirdChild.menu_collectionid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        } onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/" +
                                                            thirdChild
                                                              .collection
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }

                                                  else if (
                                                    thirdChild.menu_pageid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                        onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/" +
                                                            thirdChild.pages
                                                              .page_url
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  } else {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                        onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            thirdChild.menu_customlink
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      );
                                    }
                                  } else if (firstChild.menu_collectionid > 0) {
                                    if (firstChild.menu_show_image === 1) {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild} onClick={clearSession}
                                        >
                                          <div className="mega-menu-imgbox">
                                            <a
                                              href={
                                                "/collection/" +
                                                firstChild.collection
                                                  .cat_slug
                                              }
                                            >
                                              <img
                                                src={
                                                  firstChild.menu_image !=
                                                    null
                                                    ? imageUrl +
                                                    firstChild.menu_image
                                                    : constant.DEFAULT_IMAGE
                                                }
                                                width="100%"
                                                height="100%"
                                              />
                                              {firstChild.menu_desc ?
                                                <div
                                                  className="mega-menu-imgbox-content"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      firstChild.menu_desc,
                                                  }}
                                                ></div> : null}

                                            </a>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mmlist">
                                            <div className="mmlist-title" onClick={clearSession} >
                                              <a
                                                href={
                                                  "/collection/" +
                                                  firstChild.collection
                                                    .cat_slug
                                                }
                                              >
                                                {firstChild.menu_name}
                                              </a>
                                            </div>
                                            <ul>
                                              {firstChild.children.map(
                                                (
                                                  thirdChild,
                                                  indexThirdChild
                                                ) => {
                                                  if (
                                                    thirdChild.menu_categoryid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        } onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/category/" +
                                                            thirdChild
                                                              .categories
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                  else if (
                                                    thirdChild.menu_collectionid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        } onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/" +
                                                            thirdChild
                                                              .collection
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                  else if (
                                                    thirdChild.menu_pageid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                        onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/" +
                                                            thirdChild.pages
                                                              .page_url
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  } else {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                        onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            thirdChild.menu_customlink
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      );
                                    }
                                  } else if (firstChild.menu_pageid > 0) {
                                    if (firstChild.menu_show_image === 1) {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mega-menu-imgbox" onClick={clearSession} >
                                            <a
                                              href={
                                                "/" +
                                                firstChild.pages.page_url
                                              }
                                            >
                                              <img
                                                src={
                                                  firstChild.menu_image !=
                                                    null
                                                    ? imageUrl +
                                                    firstChild.menu_image
                                                    : constant.DEFAULT_IMAGE
                                                }
                                                width="100%"
                                                height="100%"
                                              />
                                              {firstChild.menu_desc ?
                                                <div
                                                  className="mega-menu-imgbox-content"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      firstChild.menu_desc,
                                                  }}
                                                ></div> : null}
                                            </a>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mmlist">
                                            <div className="mmlist-title" onClick={clearSession} >
                                              <a
                                                href={
                                                  "/" +
                                                  firstChild.pages.page_url
                                                }
                                              >
                                                {firstChild.menu_name}
                                              </a>
                                            </div>
                                            <ul>
                                              {firstChild.children.map(
                                                (
                                                  thirdChild,
                                                  indexThirdChild
                                                ) => {
                                                  if (
                                                    thirdChild.menu_categoryid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                        onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/category/" +
                                                            thirdChild
                                                              .categories
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                  else if (
                                                    thirdChild.menu_collectionid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                        onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/" +
                                                            thirdChild
                                                              .collection
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }

                                                  else if (
                                                    thirdChild.menu_pageid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            "/" +
                                                            thirdChild.pages
                                                              .page_url
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  } else {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            thirdChild.menu_customlink
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      );
                                    }
                                  } else {
                                    if (firstChild.menu_show_image === 1) {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mega-menu-imgbox">
                                            <a
                                              href={firstChild.menu_customlink}
                                            >
                                              <img
                                                src={
                                                  firstChild.menu_image !=
                                                    null
                                                    ? imageUrl +
                                                    firstChild.menu_image
                                                    : constant.DEFAULT_IMAGE
                                                }
                                                width="100%"
                                                height="100%"
                                              />
                                              {firstChild.menu_desc ?
                                                <div
                                                  className="mega-menu-imgbox-content"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      firstChild.menu_desc,
                                                  }}
                                                ></div> : null}
                                            </a>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mmlist">
                                            <div className="mmlist-title">
                                              <a
                                                href={
                                                  firstChild.menu_customlink
                                                }
                                              >
                                                {firstChild.menu_name}
                                              </a>
                                            </div>
                                            <ul>
                                              {firstChild.children.map(
                                                (
                                                  thirdChild,
                                                  indexThirdChild
                                                ) => {
                                                  if (
                                                    thirdChild.menu_categoryid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/category/" +
                                                            thirdChild
                                                              .categories
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                  else if (
                                                    thirdChild.menu_collectionid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/" +
                                                            thirdChild
                                                              .collection
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }

                                                  else if (
                                                    thirdChild.menu_pageid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            "/" +
                                                            thirdChild.pages
                                                              .page_url
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  } else {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            thirdChild.menu_customlink
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      );
                                    }
                                  }
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  } else if (parent.menu_collectionid > 0) {
                    return (
                      <li
                        className="nav-item dropdown mega-droupdown dropdown-hover"
                        key={indexParent} onClick={clearSession}
                      >
                        <a
                          className="nav-link "
                          href={"/collection/" + parent.collection.cat_slug}
                          id="navbarDropdown"

                        >
                          {parent.menu_name}
                        </a>
                        <div
                          className="mega-menu dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <div className="container">
                            <div className="row g-3">
                              {parent.children.map(
                                (firstChild, indexFirstChild) => {
                                  if (firstChild.menu_categoryid > 0) {
                                    if (firstChild.menu_show_image === 1) {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild} onClick={clearSession}
                                        >
                                          <div className="mega-menu-imgbox">
                                            <a
                                              href={
                                                "/collection/category/" +
                                                firstChild.categories
                                                  .cat_slug
                                              }
                                            >
                                              <img
                                                src={
                                                  firstChild.menu_image !=
                                                    null
                                                    ? imageUrl +
                                                    firstChild.menu_image
                                                    : constant.DEFAULT_IMAGE
                                                }
                                                width="100%"
                                                height="100%"
                                              />
                                              {firstChild.menu_desc ?
                                                <div
                                                  className="mega-menu-imgbox-content"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      firstChild.menu_desc,
                                                  }}
                                                ></div> : null}

                                            </a>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mmlist">
                                            <div className="mmlist-title" onClick={clearSession} >
                                              <a
                                                href={
                                                  "/collection/category/" +
                                                  firstChild.categories
                                                    .cat_slug
                                                }
                                              >
                                                {firstChild.menu_name}
                                              </a>
                                            </div>
                                            <ul>
                                              {firstChild.children.map(
                                                (
                                                  thirdChild,
                                                  indexThirdChild
                                                ) => {
                                                  if (
                                                    thirdChild.menu_categoryid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        } onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/category/" +
                                                            thirdChild
                                                              .categories
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                  if (
                                                    thirdChild.menu_collectionid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        } onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/" +
                                                            thirdChild
                                                              .collection
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                  else if (
                                                    thirdChild.menu_pageid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                        onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/" +
                                                            thirdChild.pages
                                                              .page_url
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  } else {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                        onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            thirdChild.menu_customlink
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      );
                                    }
                                  } else if (firstChild.menu_collectionid > 0) {
                                    if (firstChild.menu_show_image === 1) {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild} onClick={clearSession}
                                        >
                                          <div className="mega-menu-imgbox">
                                            <a
                                              href={
                                                "/collection/" +
                                                firstChild.collection
                                                  .cat_slug
                                              }
                                            >
                                              <img
                                                src={
                                                  firstChild.menu_image !=
                                                    null
                                                    ? imageUrl +
                                                    firstChild.menu_image
                                                    : constant.DEFAULT_IMAGE
                                                }
                                                width="100%"
                                                height="100%"
                                              />
                                              {firstChild.menu_desc ?
                                                <div
                                                  className="mega-menu-imgbox-content"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      firstChild.menu_desc,
                                                  }}
                                                ></div> : null}

                                            </a>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mmlist">

                                            <div className="mmlist-title" onClick={clearSession} >

                                              <a
                                                href={
                                                  "/collection/" +
                                                  firstChild.collection
                                                    .cat_slug
                                                }
                                              >
                                                {firstChild.menu_name}
                                              </a>
                                            </div>
                                            <ul>
                                              {firstChild.children.map(
                                                (
                                                  thirdChild,
                                                  indexThirdChild
                                                ) => {
                                                  if (
                                                    thirdChild.menu_categoryid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        } onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/category/" +
                                                            thirdChild
                                                              .categories
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }

                                                  if (
                                                    thirdChild.menu_collectionid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        } onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/" +
                                                            thirdChild
                                                              .collection
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                  else if (
                                                    thirdChild.menu_pageid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                        onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/" +
                                                            thirdChild.pages
                                                              .page_url
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  } else {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                        onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            thirdChild.menu_customlink
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      );
                                    }
                                  } else if (firstChild.menu_pageid > 0) {
                                    if (firstChild.menu_show_image === 1) {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mega-menu-imgbox" onClick={clearSession} >
                                            <a
                                              href={
                                                "/" +
                                                firstChild.pages.page_url
                                              }
                                            >
                                              <img
                                                src={
                                                  firstChild.menu_image !=
                                                    null
                                                    ? imageUrl +
                                                    firstChild.menu_image
                                                    : constant.DEFAULT_IMAGE
                                                }
                                                width="100%"
                                                height="100%"
                                              />
                                              {firstChild.menu_desc ?
                                                <div
                                                  className="mega-menu-imgbox-content"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      firstChild.menu_desc,
                                                  }}
                                                ></div> : null}
                                            </a>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mmlist">
                                            <div className="mmlist-title" onClick={clearSession} >
                                              <a
                                                href={
                                                  "/" +
                                                  firstChild.pages.page_url
                                                }
                                              >
                                                {firstChild.menu_name}
                                              </a>
                                            </div>
                                            <ul>
                                              {firstChild.children.map(
                                                (
                                                  thirdChild,
                                                  indexThirdChild
                                                ) => {
                                                  if (
                                                    thirdChild.menu_categoryid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                        onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/category/" +
                                                            thirdChild
                                                              .categories
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }

                                                  else if (
                                                    thirdChild.menu_collectionid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                        onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/" +
                                                            thirdChild
                                                              .collection
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                  else if (
                                                    thirdChild.menu_pageid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            "/" +
                                                            thirdChild.pages
                                                              .page_url
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  } else {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            thirdChild.menu_customlink
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      );
                                    }
                                  } else {
                                    if (firstChild.menu_show_image === 1) {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mega-menu-imgbox">
                                            <a
                                              href={firstChild.menu_customlink}
                                            >
                                              <img
                                                src={
                                                  firstChild.menu_image !=
                                                    null
                                                    ? imageUrl +
                                                    firstChild.menu_image
                                                    : constant.DEFAULT_IMAGE
                                                }
                                                width="100%"
                                                height="100%"
                                              />
                                              {firstChild.menu_desc ?
                                                <div
                                                  className="mega-menu-imgbox-content"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      firstChild.menu_desc,
                                                  }}
                                                ></div> : null}
                                            </a>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mmlist">
                                            <div className="mmlist-title">
                                              <a
                                                href={
                                                  firstChild.menu_customlink
                                                }
                                              >
                                                {firstChild.menu_name}
                                              </a>
                                            </div>
                                            <ul>
                                              {firstChild.children.map(
                                                (
                                                  thirdChild,
                                                  indexThirdChild
                                                ) => {
                                                  if (
                                                    thirdChild.menu_categoryid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/category/" +
                                                            thirdChild
                                                              .categories
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                  if (
                                                    thirdChild.menu_collectionid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/" +
                                                            thirdChild
                                                              .collection
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                  else if (
                                                    thirdChild.menu_pageid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            "/" +
                                                            thirdChild.pages
                                                              .page_url
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  } else {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            thirdChild.menu_customlink
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      );
                                    }
                                  }
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  } else if (parent.menu_pageid > 0) {
                    return (
                      <li
                        className="nav-item dropdown mega-droupdown dropdown-hover"
                        key={indexParent} onClick={clearSession}
                      >
                        <a
                          className="nav-link "
                          href={"/" + parent.pages.page_url}
                          id="navbarDropdown"

                        >
                          {parent.menu_name}
                        </a>
                        <div
                          className="mega-menu dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <div className="container">
                            <div className="row g-3">
                              {parent.children.map(
                                (firstChild, indexFirstChild) => {
                                  if (firstChild.menu_categoryid > 0) {
                                    if (firstChild.menu_show_image === 1) {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mega-menu-imgbox" onClick={clearSession} >
                                            <a
                                              href={
                                                "/collection/category/" +
                                                firstChild.categories
                                                  .cat_slug
                                              }
                                            >
                                              <img
                                                src={
                                                  firstChild.menu_image !=
                                                    null
                                                    ? imageUrl +
                                                    firstChild.menu_image
                                                    : constant.DEFAULT_IMAGE
                                                }
                                                width="100%"
                                                height="100%"
                                              />
                                              {firstChild.menu_desc ?
                                                <div
                                                  className="mega-menu-imgbox-content"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      firstChild.menu_desc,
                                                  }}
                                                ></div> : null}
                                            </a>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mmlist">
                                            <div className="mmlist-title" onClick={clearSession} >
                                              <a
                                                href={
                                                  "/collection/category/" +
                                                  firstChild.categories
                                                    .cat_slug
                                                }
                                              >
                                                {firstChild.menu_name}
                                              </a>
                                            </div>
                                            <ul>
                                              {firstChild.children.map(
                                                (
                                                  thirdChild,
                                                  indexThirdChild
                                                ) => {
                                                  if (
                                                    thirdChild.menu_categoryid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        } onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/category/" +
                                                            thirdChild
                                                              .categories
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                  else if (
                                                    thirdChild.menu_collectionid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        } onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/" +
                                                            thirdChild
                                                              .collection
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }

                                                  else if (
                                                    thirdChild.menu_pageid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            "/" +
                                                            thirdChild.pages
                                                              .page_url
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  } else {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            thirdChild.menu_customlink
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      );
                                    }
                                  } else if (firstChild.menu_collectionid > 0) {
                                    if (firstChild.menu_show_image === 1) {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mega-menu-imgbox" onClick={clearSession} >
                                            <a
                                              href={
                                                "/collection/" +
                                                firstChild.collection
                                                  .cat_slug
                                              }
                                            >
                                              <img
                                                src={
                                                  firstChild.menu_image !=
                                                    null
                                                    ? imageUrl +
                                                    firstChild.menu_image
                                                    : constant.DEFAULT_IMAGE
                                                }
                                                width="100%"
                                                height="100%"
                                              />
                                              {firstChild.menu_desc ?
                                                <div
                                                  className="mega-menu-imgbox-content"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      firstChild.menu_desc,
                                                  }}
                                                ></div> : null}
                                            </a>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mmlist">
                                            <div className="mmlist-title" onClick={clearSession} >
                                              <a
                                                href={
                                                  "/collection/" +
                                                  firstChild.collection
                                                    .cat_slug
                                                }
                                              >
                                                {firstChild.menu_name}
                                              </a>
                                            </div>
                                            <ul>
                                              {firstChild.children.map(
                                                (
                                                  thirdChild,
                                                  indexThirdChild
                                                ) => {
                                                  if (
                                                    thirdChild.menu_categoryid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        } onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/category/" +
                                                            thirdChild
                                                              .categories
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                  else if (
                                                    thirdChild.menu_collectionid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        } onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/" +
                                                            thirdChild
                                                              .collection
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                  else if (
                                                    thirdChild.menu_pageid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            "/" +
                                                            thirdChild.pages
                                                              .page_url
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  } else {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            thirdChild.menu_customlink
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      );
                                    }
                                  } else if (firstChild.menu_pageid > 0) {
                                    if (firstChild.menu_show_image === 1) {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mega-menu-imgbox">
                                            <a
                                              href={
                                                "/" +
                                                firstChild.pages.page_url
                                              }
                                            >
                                              <img
                                                src={
                                                  firstChild.menu_image !=
                                                    null
                                                    ? imageUrl +
                                                    firstChild.menu_image
                                                    : constant.DEFAULT_IMAGE
                                                }
                                                width="100%"
                                                height="100%"
                                              />
                                              {firstChild.menu_desc ?
                                                <div
                                                  className="mega-menu-imgbox-content"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      firstChild.menu_desc,
                                                  }}
                                                ></div> : null}
                                            </a>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mmlist">
                                            <div className="mmlist-title" onClick={clearSession} >
                                              <a
                                                href={
                                                  "/" +
                                                  firstChild.pages.page_url
                                                }
                                              >
                                                {firstChild.menu_name}
                                              </a>
                                            </div>
                                            <ul>
                                              {firstChild.children.map(
                                                (
                                                  thirdChild,
                                                  indexThirdChild
                                                ) => {
                                                  if (
                                                    thirdChild.menu_categoryid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        } onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/category/" +
                                                            thirdChild
                                                              .categories
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                  else if (
                                                    thirdChild.menu_collectionid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        } onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/" +
                                                            thirdChild
                                                              .collection
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                  else if (
                                                    thirdChild.menu_pageid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            "/" +
                                                            thirdChild.pages
                                                              .page_url
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  } else {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            thirdChild.menu_customlink
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      );
                                    }
                                  } else {
                                    if (firstChild.menu_show_image === 1) {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mega-menu-imgbox">
                                            <a
                                              href={
                                                firstChild.menu_customlink
                                              }
                                            >
                                              <img
                                                src={
                                                  firstChild.menu_image !=
                                                    null
                                                    ? imageUrl +
                                                    firstChild.menu_image
                                                    : constant.DEFAULT_IMAGE
                                                }
                                                width="100%"
                                                height="100%"
                                              />
                                              {firstChild.menu_desc ?
                                                <div
                                                  className="mega-menu-imgbox-content"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      firstChild.menu_desc,
                                                  }}
                                                ></div> : null}
                                            </a>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mmlist">
                                            <div className="mmlist-title" onClick={clearSession} >
                                              <a
                                                href={
                                                  firstChild.menu_customlink
                                                }
                                              >
                                                {firstChild.menu_name}
                                              </a>
                                            </div>
                                            <ul>
                                              {firstChild.children.map(
                                                (
                                                  thirdChild,
                                                  indexThirdChild
                                                ) => {
                                                  if (
                                                    thirdChild.menu_categoryid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        } onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/category/" +
                                                            thirdChild
                                                              .categories
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                  else if (
                                                    thirdChild.menu_collectionid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        } onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/" +
                                                            thirdChild
                                                              .collection
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                  else if (
                                                    thirdChild.menu_pageid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            "/" +
                                                            thirdChild.pages
                                                              .page_url
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  } else {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            thirdChild.menu_customlink
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      );
                                    }
                                  }
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  } else {
                    return (
                      <li
                        className="nav-item dropdown mega-droupdown dropdown-hover"
                        key={indexParent} onClick={clearSession}
                      >
                        <a
                          className="nav-link "
                          href={parent.menu_customlink}
                          id="navbarDropdown"

                        >
                          {parent.menu_name}
                        </a>
                        <div
                          className="mega-menu dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <div className="container">
                            <div className="row g-3">
                              {parent.children.map(
                                (firstChild, indexFirstChild) => {
                                  if (firstChild.menu_categoryid > 0) {
                                    if (firstChild.menu_show_image === 1) {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mega-menu-imgbox" onClick={clearSession} >
                                            <a
                                              href={
                                                "/collection/category/" +
                                                firstChild.categories
                                                  .cat_slug
                                              }
                                            >
                                              <img
                                                src={
                                                  firstChild.menu_image !=
                                                    null
                                                    ? imageUrl +
                                                    firstChild.menu_image
                                                    : constant.DEFAULT_IMAGE
                                                }
                                                width="100%"
                                                height="100%"
                                              />
                                              {firstChild.menu_desc ?
                                                <div
                                                  className="mega-menu-imgbox-content"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      firstChild.menu_desc,
                                                  }}
                                                ></div> : null}
                                            </a>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mmlist">
                                            <div className="mmlist-title" onClick={clearSession} >
                                              <a
                                                href={
                                                  "/collection/category/" +
                                                  firstChild.categories
                                                    .cat_slug
                                                }
                                              >
                                                {firstChild.menu_name}
                                              </a>
                                            </div>
                                            <ul>
                                              {firstChild.children.map(
                                                (
                                                  thirdChild,
                                                  indexThirdChild
                                                ) => {
                                                  if (
                                                    thirdChild.menu_collectionid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        } onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/" +
                                                            thirdChild
                                                              .collection
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.collection.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                  else if (
                                                    thirdChild.menu_categoryid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        } onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/category/" +
                                                            thirdChild
                                                              .categories
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  } else if (
                                                    thirdChild.menu_pageid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            "/" +
                                                            thirdChild.pages
                                                              .page_url
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  } else {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            thirdChild.menu_customlink
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      );
                                    }
                                  } else if (firstChild.menu_collectionid > 0) {
                                    if (firstChild.menu_show_image === 1) {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mega-menu-imgbox" onClick={clearSession} >
                                            <a
                                              href={
                                                "/collection/" +
                                                firstChild.collection
                                                  .cat_slug
                                              }
                                            >
                                              <img
                                                src={
                                                  firstChild.menu_image !=
                                                    null
                                                    ? imageUrl +
                                                    firstChild.menu_image
                                                    : constant.DEFAULT_IMAGE
                                                }
                                                width="100%"
                                                height="100%"
                                              />
                                              {firstChild.menu_desc ?
                                                <div
                                                  className="mega-menu-imgbox-content"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      firstChild.menu_desc,
                                                  }}
                                                ></div> : null}
                                            </a>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mmlist">
                                            <div className="mmlist-title" onClick={clearSession} >
                                              <a
                                                href={
                                                  "/collection/" +
                                                  firstChild.collection
                                                    .cat_slug
                                                }
                                              >
                                                {firstChild.menu_name}
                                              </a>
                                            </div>
                                            <ul>
                                              {firstChild.children.map(
                                                (
                                                  thirdChild,
                                                  indexThirdChild
                                                ) => {
                                                  if (
                                                    thirdChild.menu_collectionid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        } onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/" +
                                                            thirdChild
                                                              .collection
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.collection.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }

                                                  else if (
                                                    thirdChild.menu_categoryid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        } onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/category/" +
                                                            thirdChild
                                                              .categories
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  } else if (
                                                    thirdChild.menu_pageid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            "/" +
                                                            thirdChild.pages
                                                              .page_url
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  } else {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            thirdChild.menu_customlink
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      );
                                    }
                                  } else if (firstChild.menu_pageid > 0) {
                                    if (firstChild.menu_show_image === 1) {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mega-menu-imgbox">
                                            <a
                                              href={
                                                "/" +
                                                firstChild.pages.page_url
                                              }
                                            >
                                              <img
                                                src={
                                                  firstChild.menu_image !=
                                                    null
                                                    ? imageUrl +
                                                    firstChild.menu_image
                                                    : constant.DEFAULT_IMAGE
                                                }
                                                width="100%"
                                                height="100%"
                                              />
                                              {firstChild.menu_desc ?
                                                <div
                                                  className="mega-menu-imgbox-content"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      firstChild.menu_desc,
                                                  }}
                                                ></div> : null}
                                            </a>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mmlist">
                                            <div className="mmlist-title" onClick={clearSession} >
                                              <a
                                                href={
                                                  "/" +
                                                  firstChild.pages.page_url
                                                }
                                              >
                                                {firstChild.menu_name}
                                              </a>
                                            </div>
                                            <ul>
                                              {firstChild.children.map(
                                                (
                                                  thirdChild,
                                                  indexThirdChild
                                                ) => {
                                                  if (
                                                    thirdChild.menu_categoryid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/category/" +
                                                            thirdChild
                                                              .categories
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  } else if (
                                                    thirdChild.menu_collectionid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/" +
                                                            thirdChild
                                                              .collection
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                  else if (
                                                    thirdChild.menu_pageid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            "/" +
                                                            thirdChild.pages
                                                              .page_url
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  } else {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            thirdChild.menu_customlink
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      );
                                    }
                                  } else {
                                    if (firstChild.menu_show_image === 1) {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mega-menu-imgbox">
                                            <a href={firstChild.menu_customlink} >
                                              <img
                                                src={
                                                  firstChild.menu_image !=
                                                    null
                                                    ? imageUrl +
                                                    firstChild.menu_image
                                                    : constant.DEFAULT_IMAGE
                                                }
                                                width="100%"
                                                height="100%"
                                              />
                                              {firstChild.menu_desc ?
                                                <div
                                                  className="mega-menu-imgbox-content"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      firstChild.menu_desc,
                                                  }}
                                                ></div> : null}
                                            </a>
                                          </div>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          className="col"
                                          key={indexFirstChild}
                                        >
                                          <div className="mmlist">
                                            <div className="mmlist-title" onClick={clearSession} >
                                              <a
                                                href={
                                                  firstChild.menu_customlink
                                                }
                                              >
                                                {firstChild.menu_name}
                                              </a>
                                            </div>
                                            <ul>
                                              {firstChild.children.map(
                                                (
                                                  thirdChild,
                                                  indexThirdChild
                                                ) => {
                                                  if (
                                                    thirdChild.menu_collectionid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        } onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/" +
                                                            thirdChild
                                                              .collection
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                  else if (
                                                    thirdChild.menu_categoryid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        } onClick={clearSession}
                                                      >
                                                        <a
                                                          href={
                                                            "/collection/category/" +
                                                            thirdChild
                                                              .categories
                                                              .cat_slug
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  } else if (
                                                    thirdChild.menu_pageid >
                                                    0
                                                  ) {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            "/" +
                                                            thirdChild.pages
                                                              .page_url
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  } else {
                                                    return (
                                                      <li
                                                        key={
                                                          indexThirdChild
                                                        }
                                                      >
                                                        <a
                                                          href={
                                                            thirdChild.menu_customlink
                                                          }
                                                        >
                                                          {
                                                            thirdChild.menu_name
                                                          }
                                                        </a>
                                                      </li>
                                                    );
                                                  }
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      );
                                    }
                                  }
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  }
                }
              }
            })

            : null}
          <li className="nav-item">
            {loading ? (
              <Skeleton
                height={30}
                width={150}
              />
            ) : (
              <a
                className="nav-link"
                style={{
                  background: "#FD567D",
                  borderRadius: "5px"
                }}
                href="/collection/category/janmashtami-sale"
              >
                <span style={{color: "#ffffff"}}>Janmashtami Sale</span>
              </a>
            )}
          </li>
        </ul>
      </nav>
    </>
  );
}
export default HeaderMenu;
